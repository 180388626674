<template>
    <div class="container">
        <div class="app-content">
            <div class="row mb-5 mb-md-6">
                <div class="col-12 col-md-auto">
                    <div class="hub-logo d-flex justify-content-center justify-content-md-start">
                        <img :src="area.logo?.content_url" class="logo" v-if="showLogo"
                             :alt="area.name"/>
                    </div>
                </div>
                <div class="col d-flex align-items-end" :class="{'justify-content-center mt-5 text-center':!bpUpMd}">
                    <p class="typo-1 mb-0">{{hub.welcome_text}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-auto" v-if="hub.description">
                    <div class="hub-desc-panel mb-3" v-html="hub.description">
                    </div>
                </div>
                <div class="col">
                    <div class="hub-panel p-5 d-flex flex-column justify-content-between">
                        <div>
                            <p class="typo-3" v-if="!hub.ask_for_postal_code || !meetingsList.length"
                               :class="{'text-center':!bpUpMd}">Sélectionnez le motif :</p>
                            <app-field-select-hub
                                :field="{label:'Motif *', options:hub.tree, required:true}"
                                :height="282"
                                v-model="firstChoice"
                            ></app-field-select-hub>
                            <app-field-select-hub
                                v-if="secondOptions"
                                :field="{label:'Sous motif', options:secondOptions, required:true}"
                                :height="230"
                                v-model="secondChoice"
                            ></app-field-select-hub>
                            <template v-if="meetingsList.length">
                                <app-field-text
                                    v-if="hub.ask_for_postal_code"
                                    :field="{id:'postalCode', label: hub.postal_code_field_placeholder || 'Indiquez votre code postal *'}"
                                    v-model="postalCode"
                                ></app-field-text>
                                <p class="typo-3 mt-5"
                                   :class="{'text-center':!bpUpMd}">
                                    Choisissez le mode du
                                    rendez-vous :</p>
                                <div class="row justify-content-center">
                                    <div class="col-4" v-for="meeting in meetingsList">
                                        <button
                                            :class="{
                                            active: selectedMeeting && meeting.url === selectedMeeting.url,
                                            'text-center': !bpUpMd,
                                            disabled:(hub.ask_for_postal_code && postalCode.length < 5)
                                        }"
                                            @click="selectMeeting(meeting)"
                                            class="btn btn-outline-tertiary btn-outline-tertiary--primary-hover w-100">
                                            <icon
                                                v-if="meeting.type !== 'other'"
                                                :name="meeting.icon"
                                                :class="{'me-3':bpUpMd, 'mb-2': !bpUpMd}"></icon>
                                            <br v-if="!bpUpMd">
                                            {{ meeting.label }}
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <div v-if="message" class="pt-6 text-center p-marging" style="font-size: 1.4rem" v-html="message" />
                        </div>
                        <div class="mt-3">
                            <div class="row">
                                <div class="col-12 col-md-6 d-flex align-items-end order-2 order-md-1"
                                     :class="{'mt-5':!bpUpMd}">
                                    <p class="font-2 text-primary mb-0">* Champs obligatoires</p>
                                </div>
                                <div class="col-12 col-md-6 order-1 order-md-2">
                                    <a :href="selectedMeeting?selectedMeeting.url:''" class="btn btn-primary w-100"
                                       :class="{disabled:!selectedMeeting || (hub.ask_for_postal_code && postalCode.length < 5)}">Je
                                        poursuis</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="https://apenday.com/" target="_blank" v-if="area.show_powered_by && bpUpMd">
                <logo-apenday></logo-apenday>
            </a>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch, nextTick } from 'vue';
import global from '../composables/global';
import responsive from '../composables/responsive';
import AppFieldSelectHub from '../components/form/app-field-select-hub';
import { upperFirst } from 'lodash';
import map from 'lodash/map';
import AppFieldText from '../components/form/app-field-text';

export default {
    components: { AppFieldText, AppFieldSelectHub },
    setup() {
        const { state: { meeting: { area }, hub, showLogo } } = global;
        const { bpUpMd } = responsive;
        const firstChoice = ref(null);
        const secondChoice = ref(null);
        const secondOptions = computed(() => {
            return firstChoice.value !== null ? hub.tree[firstChoice.value].children : false;
        });
        const selectedMeeting = ref(null);
        const postalCode = ref('');

        if (hub.tree.length === 1) {
            firstChoice.value = 0;
            if (secondOptions.value && secondOptions.value.length === 1) {
                secondChoice.value = 0;
            }
        }
        watch(firstChoice, () => {
            secondChoice.value = null;
            selectedMeeting.value = null;
        });
        watch(secondChoice, () => {
            selectedMeeting.value = null;
        });

        const meetingsList = computed(() => {
            let meetings = [];
            if (firstChoice.value !== null && secondChoice.value !== null) {
                meetings = hub.tree[firstChoice.value]?.children?.[secondChoice.value]?.meetings ?? [];
            } else if (firstChoice.value !== null && secondChoice.value === null) {
                meetings = hub.tree[firstChoice.value].meetings;
            }
            return map(meetings, meeting => getFormattedMeeting(meeting));
        });

        const message = computed(() => {
            if (firstChoice.value !== null && secondChoice.value !== null) {
                return hub.tree[firstChoice.value]?.children?.[secondChoice.value]?.message ?? null;
            } else if (firstChoice.value !== null && secondChoice.value === null) {
                return hub.tree[firstChoice.value].message;
            }
        });

        function getFormattedMeeting(meeting) {
            let label = meeting.typeLabel;

            switch (meeting.type) {
                case 'video':
                    label = 'Visio';
                    break;
                case 'call':
                    label = 'Téléphone';
                    break;
                case 'other':
                    label = area.label_meeting_type_other ? upperFirst(area.label_meeting_type_other) : upperFirst(area.label_agency);
                    break;
            }

            return {
                label,
                icon: meeting.type === 'video' ? 'camera' : meeting.type === 'call' ? 'phone' : 'marker-outline',
                url: meeting.url,
                type: meeting.type,
            };
        }

        function selectMeeting(meeting) {
            selectedMeeting.value = meeting;
        }

        watch(meetingsList, (newVal) => {
            if (newVal.length === 1) {
                selectedMeeting.value = newVal[0];
            }
        });

        watch(postalCode, val => {
            nextTick(() => {
                if (val.length > 5) postalCode.value = val.substring(0, 5);
                localStorage.setItem('apendayPostalCode', val);
            });
        });

        if (!hub.ask_for_postal_code) localStorage.removeItem('apendayPostalCode');

        return {
            area,
            bpUpMd,
            showLogo,
            hub,
            firstChoice,
            secondChoice,
            secondOptions,
            meetingsList,
            selectedMeeting,
            selectMeeting,
            postalCode,
            message
        };
    },
};
</script>
