import { reactive, readonly, computed, toRef, ref, watch } from 'vue';
import global from './global';
import form from './form';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import Meeting from '../services/Meeting';
import dayjs from '../dayjs';
import popin from './popin';
import responsive from './responsive';
import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

const state = reactive({
    calendarUpdateEvent: {}, // {month: number; year: number}
    monthSequences: [],
    activeDay: null, // YMD
    resetCalendarComponent: false,
    isLoading: false,
    showCalendar: false,
    noDateAvailableMsg: false, // false or string message
    showStep2: false,
    maxDelayBeforeSchedule: null, // Dayjs object | null
});

const calendar1 = ref(null);
const calendar2 = ref(null);
const firstClickOnCalendar = ref(false); // needed to avoid showing second panel on mobile
let now; // dayjs()
let monthCount;
const monthLimit = 12; // stop searching dates after x months

const { bpUpMd } = responsive;

// Getters
const vCalendarVModel = computed({
    get: () => {
        return state.activeDay ? dayjs(state.activeDay).format('YYYY-MM-DD') : null;
    },
    set: val => {
        state.activeDay = dayjs(val).format('YYYY-MM-DD');
    },
});

const showStep2 = computed(() => !bpUpMd.value && state.showStep2);

const getAvailableDates = computed(() => {
    let dates = filter(state.monthSequences, date => date.sequences.length);
    dates = map(dates, function (o) {
        return {
            highlight: {
                fillMode: 'light',
                style: {
                    backgroundColor: 'var(--calendar-active-cell-color)',
                },
            },
            dates: o.date,
        };
    });
    return dates;
});

const getDisabledDates = computed(() => {
    let dates = filter(state.monthSequences, date => date.sequences.length === 0);
    return map(dates, o => o.date);
});

const getSequences = computed(() => {
    if (state.activeDay && state.monthSequences) {
        let found = find(state.monthSequences, item => item.date === state.activeDay);
        let daySequences = found ? found.sequences : []
        if (state.maxDelayBeforeSchedule) {
            daySequences = filter(daySequences, seq => {
                return dayjs(seq.from_full).isBefore(state.maxDelayBeforeSchedule)
            });
        }
        return daySequences;
    } else {
        return [];
    }
});

const getFormatedDate = computed(() => {
    const { state: { payload } } = form;
    const date = toRef(payload, 'date');
    const timezone = toRef(payload, 'timezone');

    return dayjs(date.value).tz(timezone.value).format('dddd D MMM YYYY');
});

const getFormatedSequence = computed(() => {
    const { state: { payload } } = form;
    const { state: { meeting: { duration, duration_unit } } } = global;
    const date = toRef(payload, 'date');
    const timezone = toRef(payload, 'timezone');

    let dateStart = dayjs(date.value).tz(timezone.value);
    let dateEnd = dateStart.clone().add(duration, duration_unit.charAt(0));
    let dayEndFormat = dateEnd.format('dddd D MMM');

    if (dateStart.isSame(dateEnd, 'day')) {
        return dateStart.format('HH:mm') + ' - ' + dateEnd.format('HH:mm');
    } else {
        return `${dateStart.format('HH:mm')} - ${dateEnd.format('HH:mm')} <small>(${dayEndFormat})</small>`;
    }
});

// Setters
function updateActiveDay(value) {
    state.activeDay = value;
}

// Actions
function setupCalendar() {
    const { state: { payload } } = form;
    const formDate = toRef(payload, 'date');
    const agency = toRef(payload, 'agency');
    const consultant = toRef(payload, 'consultant');
    const locks = toRef(payload, 'locks');
    const timezone = toRef(payload, 'timezone');
    const showCalendar = toRef(state, 'showCalendar');
    const isLoading = toRef(state, 'isLoading');
    const activeDay = toRef(state, 'activeDay');
    const noDateAvailableMsg = toRef(state, 'noDateAvailableMsg');
    const stateShowStep2 = toRef(state, 'showStep2');
    const { state: { meeting: { max_delay_before_schedule, max_delay_before_schedule_unit } } } = global;
    state.maxDelayBeforeSchedule = max_delay_before_schedule ? dayjs().add(max_delay_before_schedule, max_delay_before_schedule_unit.charAt(0)) : null;
    const maxDate = computed(()=>{
        return state.maxDelayBeforeSchedule ? state.maxDelayBeforeSchedule.toDate() : null;
    })
    showCalendar.value = false;
    noDateAvailableMsg.value = false;
    stateShowStep2.value = false;
    now = dayjs();
    monthCount = 0;
    firstClickOnCalendar.value = false;
    resetCalendar();

    watch(timezone, val => onTimezoneChange());
    getFirstAvailability();

    function onWrapClick() {
        const { updateDate } = form;

        stateShowStep2.value = false;
        updateDate();
    }

    return {
        calendar1,
        calendar2,
        firstClickOnCalendar,
        showCalendar,
        formDate,
        locks,
        agency,
        consultant,
        isLoading,
        onDayClick,
        onMonthChange,
        getAvailableDates,
        getDisabledDates,
        getSequences,
        vCalendarVModel,
        activeDay,
        noDateAvailableMsg,
        showStep2,
        onWrapClick,
        maxDate
    };
}

function resetCalendar() {
    const { updateDate } = form;
    if (!form.isLockedDate()) {
        updateDate();
        state.activeDay = null;
    }
}

function onDayClick($event, from = 'calendar1') {
    if (!$event.isDisabled) {
        firstClickOnCalendar.value = true;
        state.activeDay = $event.id;
        if (!(calendar1.value && calendar2.value)) return;
        if (from === 'calendar1') {
            state.showStep2 = true;
            calendar2.value.move($event.id).then(() => {
                onCalendarUpdated();
            });
        } else {
            calendar1.value.move($event.id).then(() => {
                onCalendarUpdated();
            });
        }
    }
}

function onMonthChange($event, from = 'calendar1') {
    const { month, year } = $event;
    const activeSequences = dayjs(getFirstMonthDate());
    const req = dayjs({ month: month - 1, year });
    if (req.isSame(activeSequences, 'month')) return;

    state.isLoading = true;
    state.calendarUpdateEvent = $event;
    getMonthDeb();
    if (!(calendar1.value && calendar2.value)) return;
    if (from === 'calendar1') {
        calendar2.value.move($event);
    } else {
        calendar1.value.move($event);
    }
}

function onTimezoneChange() {
    getMonth();
}

const getMonthDeb = debounce(() => getMonthFirstAvailability(), 500);

function getMonth() {
    const { month, year } = state.calendarUpdateEvent;
    const { state: { payload } } = form;
    const { state: { meeting } } = global;
    const { showError } = popin;
    state.isLoading = true;

    let params = {};
    if (meeting.agency_selection_enabled && payload.agency) {
        params.agencyId = payload.agency.id;
    }
    if (meeting.consultant_selection_enabled && payload.consultant) {
        params.consultantId = payload.consultant.id;
    }

    params.timezone = payload.timezone;
    params.locks = payload.locks;

    return Meeting.getAvailability({
        meetingId: meeting.id,
        year,
        month,
        params,
    }).pipe(
        mergeMap(async (data) => {
            const { state: { limitedTimeSequences } } = global;

            return new Promise((resolve) => {
                if (limitedTimeSequences.length != 0) {
                    state.monthSequences = filterOnLimitedTimeSequences(data, limitedTimeSequences[`${year}-${month}`] || []);
                } else {
                    state.monthSequences = data;
                }
                state.isLoading = false;
                resolve(data);
                // setTimeout(() => resolve(data), 30000);
            });
        }),
        catchError(err => {
            showError(err);
            return throwError(err);
        }),
    );
}

function filterOnLimitedTimeSequences(originalData, limitedSequences) {
    let result = [];
    for (let row of originalData) {
        let item = {date: row.date, sequences: []};
        for (let limited of limitedSequences) {
            if (row.date === limited.date) {
                for (let rowSequence of row.sequences) {
                    for (let limitedSequence of limited.sequences) {
                        if (rowSequence.from === limitedSequence.from) {
                            item.sequences.push(rowSequence);
                        }
                    }
                }
            }
        }
        result.push(item);
    }

    return result;
}

function getMonthFirstAvailability() {
    getMonth().subscribe(() => {
        const firstMonthDate = getFirstMonthDate();
        if (firstMonthDate) {
            state.activeDay = firstMonthDate;
        }
        onCalendarUpdated();
    });
}

function getFirstAvailability() {
    const { state: { meeting: { no_availability_were_foun_error_message } } } = global;
    state.calendarUpdateEvent = { month: now.month() + 1, year: now.year() };
    getMonth().subscribe(async () => {
        if (monthCount === 0) await sleep(600); // to leave time to show loader first time
        const firstMonthDate = getFirstMonthDate();
        if (firstMonthDate) {
            state.activeDay = firstMonthDate;
            state.showCalendar = true;
            setTimeout(() => {
                onCalendarUpdated();
            }, 600);
        } else {
            now = now.add(1, 'M');
            if (monthCount > monthLimit) {
                state.noDateAvailableMsg = no_availability_were_foun_error_message ?? `Désolé, il n'y a pas de rendez-vous disponible dans les ${monthLimit} prochains mois.`;
                return;
            }
            monthCount++;
            getFirstAvailability();
        }
    });
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getFirstMonthSequence() {
    return find(state.monthSequences, seq => seq.sequences.length) ?? false;
}

function getFirstMonthDate() {
    const sequence = getFirstMonthSequence();
    return sequence ? sequence.date : false;
}

function onCalendarUpdated() {
    cleanCalendarDate();
    scrollToDate();
}

function scrollToDate() {
    const daysContainer = document.querySelector('.vc--inline .vc-weeks');
    if (!daysContainer) return;
    const { width } = daysContainer.getBoundingClientRect();
    const dayEl = daysContainer.querySelector('.id-' + state.activeDay);
    if (!dayEl) return;
    const { width: dayWidth } = dayEl.getBoundingClientRect();

    daysContainer.scrollTo({
        left: dayEl.offsetLeft - (width / 2) + (dayWidth / 2),
        behavior: 'smooth',
    });
}

function cleanCalendarDate() {
    const daysContainer = document.querySelector('.vc--inline .vc-weeks');
    if (!daysContainer) return;
    const days = daysContainer.querySelectorAll('.vc-day');
    days.forEach(day => {
        const date = day.className.match(/(\bid-\S+\b)/ig)[0].substring(3);
        if (dayjs(date).isBefore(dayjs(), 'day')) day.remove();
    });
}

export default {
    state: readonly(state),
    onDayClick,
    onMonthChange,
    onTimezoneChange,
    resetCalendar,
    updateActiveDay,
    getFormatedDate,
    getAvailableDates,
    getDisabledDates,
    getSequences,
    getFormatedSequence,
    vCalendarVModel,
    setupCalendar,
};
